import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-galeria-foto',
  templateUrl: './galeria-foto.component.html',
  styleUrls: ['./galeria-foto.component.css']
})
export class GaleriaFotoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
