<p class='parTitle'>Fotografii din viața comunității</p>
	<div id="myContainer">
	<table id="myTable">
		<tr>
			<td>
				<img src="assets/Fotografii/viata_comunitatii/1.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/2.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/3.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/4.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
		</tr>
		
		<tr>
			<td>
				<img src="assets/Fotografii/viata_comunitatii/5.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/6.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/7.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/8.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
		</tr>
		
		<tr>
			<td>
				<img src="assets/Fotografii/viata_comunitatii/9.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/10.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/11.jpg"  alt="Foto credit: Ziarul Lumina">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/12.jpg"  alt="Foto credit: Ziarul Lumina">

			</td>
		</tr>
		
		<!--tr>
			<td>
				<img src="assets/Fotografii/viata_comunitatii/13.jpg">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/14.jpg">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/15.jpg">
			</td>
			
			<td>
				<img src="assets/Fotografii/viata_comunitatii/16.jpg">
			</td>
		</tr>
		
		<tr>
			<td>
				<img src="assets/Fotografii/viata_comunitatii/17.jpg">
			</td>
			
			<td>
			</td>
			
			<td>
			</td>
			
			<td>
			</td>
		</tr-->
	</table>
	</div>