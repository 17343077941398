<div id="frame4">
    <img id="fotografiaMare" src="assets/Fotografii/1.jpg"/>
</div>
<div id="frame5">	
    <img class="fotografiaMica" src="assets/Fotografii/1.jpg" onclick="showLittleFoto(1)"/>
    <img class="fotografiaMica" src="assets/Fotografii/2.jpg" onclick="showLittleFoto(2)"/>
    <img class="fotografiaMica" src="assets/Fotografii/3.jpg" onclick="showLittleFoto(3)"/>
    <img class="fotografiaMica" src="assets/Fotografii/4.jpg" onclick="showLittleFoto(4)"/>
    <img class="fotografiaMica" src="assets/Fotografii/5.jpg" onclick="showLittleFoto(5)"/>
    <img class="fotografiaMica" src="assets/Fotografii/6.jpg" onclick="showLittleFoto(6)"/>
    <img class="fotografiaMica" src="assets/Fotografii/7.jpg" onclick="showLittleFoto(7)"/>
    <img class="fotografiaMica" src="assets/Fotografii/8.jpg" onclick="showLittleFoto(8)"/>
</div>
<div id="frame6">
    <div id="frame61">
        <!--p class="par1Subtitle">Noutăți</p-->
        <div class="frame611">
            <div class="frame6111">
                <div>
                    <a title="Foto credit: Facebook / Parohia Pantelimon II" href='https://basilica.ro/orasul-pantelimon-va-avea-o-noua-biserica-ps-timotei-prahoveanul-a-sfintit-piatra-de-temelie/' target="_blank">
                    <img width='100%' src='assets/Fotografii/facebook/noutati_1.jpg'/>
                    </a>
                </div>
                <a href='https://basilica.ro/orasul-pantelimon-va-avea-o-noua-biserica-ps-timotei-prahoveanul-a-sfintit-piatra-de-temelie/' target="_blank">PS Timotei a sfințit piatra de temelie a noii biserici din Pantelimon</a>
            </div>
        </div>
        <div class="frame611">
            <div class="frame6111">
                <div>
                    <a title="Foto credit: Protopopiatul Ilfov Sud" href='https://basilica.ro/12-zile-de-credinta-prin-joc-pentru-tinerii-din-protopopiatul-ilfov-sud/' target="_blank">
                    <img width='100%' src='assets/Fotografii/facebook/noutati_2.jpg'/>
                    </a>
                </div>
                <a href='https://basilica.ro/12-zile-de-credinta-prin-joc-pentru-tinerii-din-protopopiatul-ilfov-sud/' target="_blank">100 de copii au participat la Tabăra „Credință prin joc”</a>
            </div>
        </div>
        <div class="frame611">
            <div class="frame6111">
                <div>
                    <a title="Foto credit: Protopopiatul Ilfov Sud" href='https://basilica.ro/provocari-si-perspective-satul-romanesc-a-fost-evocat-in-conferinta-preotilor-din-ilfov-sud/' target="_blank">
                    <img width='100%' src='assets/Fotografii/facebook/noutati_3.jpg'/>
                    </a>
                </div>
                <a href='https://basilica.ro/provocari-si-perspective-satul-romanesc-a-fost-evocat-in-conferinta-preotilor-din-ilfov-sud/' target="_blank">Conferința clericilor din Protopopiatul Ilfov Sud</a>
            </div>
        </div>
        <div class="frame611">
            <div class="frame6111">
                <div>
                    <a title="Foto credit: Protopopiatul Ilfov Sud" href='https://basilica.ro/sfintirea-capelei-parohiei-pantelimon-ii-prot-ilfov-sud/' target="_blank">
                    <img width='100%' src='assets/Fotografii/facebook/noutati_4.jpg'/>
                    </a>
                </div>
                <a href='https://basilica.ro/sfintirea-capelei-parohiei-pantelimon-ii-prot-ilfov-sud/' target="_blank">A fost sfințită capela Parohiei Pantelimon II</a>
            </div>
        </div>     
    </div>		
    <!--div id="frame62">
        <p class="par1Subtitle">Anunțuri</p>
    </div-->				
    <div id="frame63">
        <div>
            <a title="Foto credit: Agenția de știri Basilica.ro" href='https://basilica.ro/' target="_blank">
            <img width='100%' src='assets/IMG/Basilica.png'/>
        </a>
        </div>
        <div>
            <br>
            <a title="Foto credit: Patriarhia Română - Trinitas TV" href='https://www.trinitas.ro/' target="_blank">
            <img width='100%' src='assets/IMG/Trinitas.png'/>
            </a>
        </div>
        <div>
            <br>
            <a title="Foto credit: Facebook Ziarul Lumina" href='https://ziarullumina.ro/' target="_blank">
            <img width='100%' src='assets/IMG/Lumina_1.png'/>
            </a>
        </div>
        <div>
            <br>
            <a title="Foto credit: Doxologia.ro - portal ortodox" href='https://doxologia.ro/' target="_blank">
            <img width='100%' src='assets/IMG/Doxologia.png'/>
            </a>
        </div>
    </div>	
</div>