import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from './app.component';
import { PrimaPaginaComponent } from './prima-pagina/prima-pagina.component';
import { GaleriaFotoComponent } from './galeria-foto/galeria-foto.component';

@NgModule({
  declarations: [
    AppComponent,
    PrimaPaginaComponent,
    GaleriaFotoComponent
  ],
  imports: [
    BrowserModule, 
    NgbModule,
    PdfViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
